import React from 'react';
import nogameBackground from '../../assets/backgrounds/nogamebackground.png'; // Εικόνα φόντου
import './Nogame.css';


function Nogame() {
  return (
    <div className="nogame-container" style={{ backgroundImage: `url(${nogameBackground})` }}>
      
    </div>
  );
}



export default Nogame;
