import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import infoBackground from '../../assets/backgrounds/infobackground.png'; // Εικόνα φόντου
import './GenderPage.css';

const GenderPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  return (
    <div className="gender-page-container" style={{ backgroundImage: `url(${infoBackground})` }}>
      
      <div className="buttong-container" > 
        <Link to={`/gender2?userId=${userId}`}>
          <button className="green-button">Θα συνεχίσω</button>
        </Link>
        <Link to={`/nogame?userId=${userId}`}>
          <button className="blue-button">Δεν θα συνεχίσω</button>
        </Link>
      </div>
    </div>
  );
}

export default GenderPage;
