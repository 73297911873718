import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import Slider1 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import male1Image from '../../assets/images/male-avatar-0.gif';
import male2Image from '../../assets/images/male-avatar-1.gif';
import male3Image from '../../assets/images/male-avatar-2.gif';
import maleBackground from '../../assets/backgrounds/malebackground.png'; // Εικόνα φόντου
import './Maleavatar.css'; // Βεβαιωθείτε ότι έχετε το σωστό αρχείο CSS


const Maleavatar = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const history = useNavigate();

  const [selectedAvatar, setSelectedAvatar] = useState(0); // Αρχική επιλογή στο πρώτο GIF

  
  const handleNext = async () => {
    try {
      //const selectedGif = [male1Image, male2Image, male3Image][selectedAvatar]; // Επιλέγεται το αντίστοιχο GIF

      await firestore.collection('data').doc(userId).update({
        avatar: selectedAvatar,
      });

      history(`/car?userId=${userId}`); // Μετάβαση στη σελίδα Car
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
    beforeChange: (current, next) => setSelectedAvatar(next), // Ενημέρωση της επιλογής κάθε φορά που αλλάζει το slide
  };

  return (
    <div className="male-avatar-container" style={{ backgroundImage: `url(${maleBackground})` }}>
      <Slider1 {...settings} className="avatar-slider">
        <div className={`avatar-option ${selectedAvatar === 0 ? 'selected' : ''}`}>
          <img src={male1Image} alt="Male Avatar 0" />
        </div>
        <div className={`avatar-option ${selectedAvatar === 1 ? 'selected' : ''}`}>
          <img src={male2Image} alt="Male Avatar 1" />
        </div>
        <div className={`avatar-option ${selectedAvatar === 2 ? 'selected' : ''}`}>
          <img src={male3Image} alt="Male Avatar 2" />
        </div>
        {/* Προσθέστε εδώ τις υπόλοιπες επιλογές */}
        </Slider1>
    
       <button className="next-button-male" onClick={handleNext}>
     Επόμενο
     </button>
      
    </div>
    
  );
};

export default Maleavatar;
