import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import genderBackground from '../../assets/backgrounds/genderbackground.png'; // Εικόνα φόντου
import maleImage from '../../assets/images/male-image.png'; // Εικόνα ανδρικού φύλου
import femaleImage from '../../assets/images/female-image.png'; // Εικόνα θηλυκού φύλου
import './Gender2.css';

const Gender2 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const [selectedGender, setSelectedGender] = useState(null);
  const history = useNavigate();

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
  };

  const handleNext = async () => {
    if (selectedGender !== null) {
      try {
        const genderValue = selectedGender === 0 ? 'male' : 'female';
        await firestore.collection('data').doc(userId).update({
          gender: genderValue,
        });

        if (selectedGender === 0) {
          history(`/maleavatar?userId=${userId}`);
        } else {
          history(`/femaleavatar?userId=${userId}`);
        }
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }
  };

  return (
    <div className="gender2-container" style={{ backgroundImage: `url(${genderBackground})` }}>
      <div className="gender-options">
        <div
          className={`gender-option ${selectedGender === 0 ? 'selected' : ''}`}
          onClick={() => handleGenderSelect(0)}
        >
          <img src={maleImage} alt="Male" />
        </div>
        <div
          className={`gender-option ${selectedGender === 1 ? 'selected' : ''}`}
          onClick={() => handleGenderSelect(1)}
        >
          <img src={femaleImage} alt="Female" />
        </div>
      </div>
      <button className="nextgen-button" onClick={handleNext}>
        Επόμενο
      </button>
    </div>
  );
};

export default Gender2;
