import React from 'react';
import './terms.css';

function Terms() {
    return (
        <div classname="terms-container">
            <h1>ΟΡΟΙ ΣΥΜΜΕΤΟΧΗΣ ΣΕ ΠΡΟΩΘΗΤΙΚΗ ΕΝΕΡΓΕΙΑ
            «ENERGY CHAMPIONS – Ο αγώνας για μια πιο πράσινη Θεσσαλονίκη ξεκινά τώρα!»</h1>
            <p>
            1.	ΑΝΤΙΚΕΙΜΕΝΟ
H ανώνυμη εταιρεία με την επωνυμία «ΦΥΣΙΚΟ ΑΕΡΙΟ ΕΛΛΗΝΙΚΗ ΕΤΑΙΡΙΑ ΕΝΕΡΓΕΙΑΣ (εφεξής «ΦΥΣΙΚΟ ΑΕΡΙΟ» ή «Διοργανώτρια») που εδρεύει στην  Λεωφόρος  Κηφισίας 7, 115 23 Αμπελόκηποι,  σε συνεργασία με την εταιρεία με την επωνυμία MIND SETS I.K.E. που εδρεύει στην Καστοριάς 13-15, Γέρακας με ΑΦΜ 800691318 , ΔΟΥ ΠΑΛΛΗΝΗΣ και η οποία εκπροσωπείται νόμιμα στο παρόν από τον κ. Νικήτα Τικόπουλο (εφεξής «MINDSETS» ή «Διαφημιστική Εταιρεία»), διοργανώνει Προωθητική Ενέργεια με τον τίτλο «ENERGY CHAMPIONS – Ο αγώνας για μια πιο πράσινη Θεσσαλονίκη ξεκινάει τώρα!» που περιλαμβάνει διαδικασία νίκης μέσω κλήρωσης για τη διάρκεια της παρουσίας του στην 88η Διεθνή Έκθεση Θεσσαλονίκης (ΔΕΘ) από τις 07/09/2024 εώς τις 15/09/2024 (εφ’ εξής «Προωθητική Ενέργεια» ή «Διαγωνισμός»), στο πλαίσιο προώθησης και προβολής των προϊόντων και των υπηρεσιών του ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ, για συγκεκριμένο χρονικό διάστημα και σύμφωνα τους όρους συμμετοχής, που είναι δημοσιευμένοι στην ιστοσελίδα του ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ. Η Διοργανώτρια παρέχει τα δώρα προς τους συμμετέχοντες στον Διαγωνισμό υπό τους παρόντες όρους. 
Η Διοργανώτρια, επιφυλάσσεται του δικαιώματός της  να τροποποιεί οποτεδήποτε μονομερώς τους παρόντες όρους, όπως ενδεικτικά να αλλάξει τα δώρα ή και να παρατείνει τη διάρκεια της Προωθητικής Ενέργειας ή και να την ακυρώσει. H τυχόν παράταση της διάρκειας της Προωθητικής Ενέργειας ή η οποιαδήποτε μεταβολή των παρόντων όρων θα πραγματοποιείται με κατάθεση στο Συμβολαιογράφο και κατόπιν προηγούμενης ανακοίνωσης στην άνω αναφερόμενη ιστοσελίδα και θα είναι δεσμευτική για κάθε συμμετέχοντα. Η Διοργανώτρια δεν αναλαμβάνει οποιαδήποτε ευθύνη αναφορικά με οποιαδήποτε τέτοια μεταβολή είτε έναντι των συμμετεχόντων ή/και οποιουδήποτε τρίτου.
2.	ΔΙΑΡΚΕΙΑ
Η Προωθητική Ενέργεια θα διαρκέσει από τις 07/09/2024 έως και τις 15/09/2024 και θα πραγματοποιηθεί στη Θεσσαλονίκη.
3.	ΔΙΚΑΙΩΜΑ ΣΥΜΜΕΤΟΧΗΣ
Δικαίωμα συμμετοχής στην Προωθητική Ενέργεια έχουν όλοι όσοι  διαμένουν νόμιμα και μόνιμα στην Ελλάδα, έχουν συμπληρώσει το 18ο έτος της ηλικίας τους και είναι ικανοί για κάθε δικαιοπραξία. Εξαιρούνται οι εργαζόμενοι στην εταιρία ΦΥΣΙΚΟ ΑΕΡΙΟ, εν γένει στις εταιρείες του Ομίλου ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ και MIND SETS, καθώς και στις εταιρίες που συνεργάζονται με την τελευταία για τη διενέργεια της Προωθητικής Ενέργειας, όλοι οι ιδιοκτήτες και εργαζόμενοι σε καταστήματα ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ, αλλά και οι σύζυγοι, καθώς και οι συγγενείς α’ και β’ βαθμού, όλων των προαναφερόμενων.
4.	ΤΡΟΠΟΙ ΣΥΜΜΕΤΟΧΗΣ 
Από την ημερομηνία έναρξης της Προωθητικής Ενέργειας (07/09/2024) το κοινό θα μπορεί να λάβει μέρος στον διαγωνισμό επισκεπτόμενο το περίπτερο της εταιρείας ΦΥΣΙΚΟ ΑΕΡΙΟ στην 88η Διεθνή Έκθεση Θεσσαλονίκης. Ειδικότερα, μέσα απο μια ηλεκτρονική εφαρμογή και αφού συμπληρώσει τα στοιχεία του, ο συμμετέχων μπορεί να λάβει μέρος στο Διαγωνισμό.   
Τα μέσα / τρόποι με τους οποίους θα έχει τη δυνατότητα ο συμμετέχων να κάνει χρήση της εφαρμογής είναι οι παρακάτω:
•	Να επισκεφτεί την 88η  Διεθνή Έκθεση Θεσσαλονίκης, από 07/09/2024 έως και 15/09/2024 και να λάβει το ενημερωτικό έντυπο από την προωθητική ομάδα.
•	Να επισκεφτεί το περίπτερο του Φυσικού Αερίου στην 88η  Διεθνή Έκθεση Θεσσαλονίκης, από 07/09/2024  έως και 15/09/2024.
•	Να συμμετέχει στο παιχνίδι στο περίπτερο της Διοργανώτριας και να έχει συμπληρώσει τη σχετική φόρμα που του παρέχεται μέσω εφαρμογής στο κινητό του από τη Διοργανώτρια στην οποία συμπληρώνονται τα ακόλουθα προσωπικά δεδομένα του συμμετέχοντος: Όνομα, Επώνυμο, Κινητό τηλέφωνο, Email.  

5.	ΛΟΓΟΙ ΑΠΟΚΛΕΙΣΜΟΥ – ΑΚΥΡΩΣΗ ΣΥΜΜΕΤΟΧΩΝ:
Οποιαδήποτε συμμετοχή μπορεί να ακυρωθεί για τους εξής λόγους:
(α) λόγω κωλύματος του συμμετέχοντος βάσει του όρου 4 παραπάνω,
(β) λόγω μη εντοπισμού είτε κάποιου τυχερού της Προωθητικής Ενέργειας, καθιστώντας έτσι αδύνατη την ενημέρωσή τους ή λόγω επίδειξης εκ μέρους τους αμέλειας, αδιαφορίας ή αδράνειας ως προς την κατοχύρωση και παραλαβή του δώρου τους,
(γ) λόγω μη συμμόρφωσης και αποδοχής από οποιοδήποτε συμμετέχοντα των παρόντων όρων στο σύνολό τους (συμπεριλαμβανομένων και των παραρτημάτων), οι οποίοι θεωρούνται όλοι ουσιώδεις, 
(δ) λόγω μη αποδοχής δώρου & συμπλήρωσης των στοιχείων στην ειδική φόρμα στοιχείων /συμμετοχής όπως προβλέπονται στους παρόντες όρους συμπεριλαμβανομένων των Παραρτημάτων,
(ε) λόγω μη δήλωσης των πραγματικών στοιχείων του συμμετέχοντος.
Στην περίπτωση που με οποιονδήποτε τρόπο αποκλειστεί η συμμετοχή κάποιου συμμετέχοντα/τυχερού/νικητή στην Προωθητική Ενέργεια  χάνει αυτός, αυτομάτως και οριστικά, το δικαίωμα του, να του αποδοθεί το δώρο και η Διοργανώτρια  και η MIND SETS απαλλάσσονται από κάθε σχετική ευθύνη ενώ η Διοργανώτρια δικαιούται να προβεί είτε στην ακύρωση των μη αποδοθέντων δώρων, είτε στην διάθεση τους με οποιαδήποτε διαδικασία ή και ελεύθερα, κατά την απόλυτη διακριτική της  ευχέρεια.
6.	ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ
H Διοργανώτρια, ως υπεύθυνη επεξεργασίας, συλλέγει και επεξεργάζεται στο πλαίσιο της Προωθητική Ενέργειας τα δεδομένα κάθε συμμετέχοντος σε αυτή.
Για τη συμμετοχή στο Διαγωνισμό συλλέγονται τα ακόλουθα προσωπικά δεδομένα των Συμμετεχόντων: 
-	Όνομα και επώνυμο 
-	Τηλέφωνο
- 	E-mail
- 	Ταχυδρομική Διεύθυνση
Η ως άνω επεξεργασία προσωπικών δεδομένων είναι αναγκαία για τους ακόλουθους σκοπούς: (α) την διεξαγωγή του Διαγωνισμού και την εκτέλεση της σύμβασης στην οποία συμμετέχουν οι συμβαλλόμενοι συμμετέχοντες , (β) την κλήρωση των νικητών, (γ) την ταυτοποίηση και ανακοίνωση των νικητών, (δ) την αποστολή ή εξαργύρωση των δώρων, (ε) την προώθηση και την προβολή της Διοργανώτριας για εμπορικούς σκοπούς και (ζ) την ανταπόκριση της Διοργανώτριας στις υποχρεώσεις της, οι οποίες απορρέουν από τον Διαγωνισμό. Η επεξεργασία των προσωπικών δεδομένων των Συμμετεχόντων και του νικητή της Προωθητικής Ενέργειας γίνεται για την εκπλήρωση των υποχρεώσεων της Διοργανώτριας που απορρέουν από τους όρους του Διαγωνισμού.  
Εντός χρονικού διαστήματος έξι (6) μηνών από την λήξη της Προωθητικής Ενέργειας, οποιαδήποτε προσωπικά δεδομένα των Συμμετεχόντων/ του νικητή θα διαγράφονται, εκτός εάν η τήρησή τους δικαιολογείται ή επιβάλλεται από την εκάστοτε ισχύουσα νομοθεσία ή για τους σκοπούς προάσπισης των εννόμων συμφερόντων της Διοργανώτριας.
Για τυχόν άσκηση των δικαιωμάτων πρόσβασης, διόρθωσης, διαγραφής, περιορισμού επεξεργασίας, αντίταξης στην επεξεργασία καθώς και φορητότητας των δεδομένων που τους αφορούν, οι Συμμετέχοντες/ ο νικητής του Διαγωνισμού μπορούν να επικοινωνούν στη διεύθυνση ηλεκτρονικού ταχυδρομείου contact-DPO@fysikoaerioellados.gr.  Τέλος, οι συμμετέχοντες/ο νικητής έχουν το δικαίωμα υποβολής καταγγελίας στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (πληροφορίες στο www.dpa.gr ). Για ενημέρωση σχετικά με την προστασία των προσωπικών σας δεδομένων, μπορείτε να απευθύνεστε  τηλεφωνικά στο 181133 από σταθερό ή από κινητό.
Η Διοργανώτρια δεν θα επεξεργαστεί  τα προσωπικά δεδομένα των συμμετεχόντων για άλλους σκοπούς πέρα από αυτούς που αναφέρονται παραπάνω. Σε περίπτωση που η Διοργανώτρια  επιθυμεί  να χρησιμοποιήσει τα δεδομένα για άλλους σκοπούς, θα το κάνει  μόνον αφού ενημερώσει τον κάθε συμμετέχοντα σχετικά και υπό την προϋπόθεση ότι έχει εξασφαλίσει την κατάλληλη νομική βάση για την εν λόγω επεξεργασία (π.χ.έχει λάβει τη σαφή και ρητή συγκατάθεσή του).
Η Διοργανώτρια για τη διοργάνωση και υποστήριξη του Διαγωνισμούσυνεργάζεται με την  μονοπρόσωπη εταιρεία περιορισμένης ευθύνης  με την επωνυμία «ΜΑΙΝΤ ΣΕΤΣ ΔΙΑΦΗΜΙΣΤΙΚΗ ΕΤΑΙΡΕΙΑ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε», με τον διακριτικό τίτλο «MIND SETS», που εδρεύει στον Γέρακα, Καστοριάς 13-15 και ενεργεί ως εκτελούσα την επεξεργασία για λογαριασμό της Διοργανώτριας. Πέραν των παραπάνω αποδεκτών η Διοργανώτρια δεν αποκαλύπτει, δεν επεξεργάζεται και δεν δημοσιοποιεί  τα προσωπικά δεδομένα σε τρίτους, με εξαίρεση τις περιπτώσεις που η κοινοποίηση/διαβίβαση τους επιβάλλεται από την ισχύουσα νομοθεσία. Η Διοργανώτρια δεν διαβιβάζει δεδομένα προσωπικού χαρακτήρα σε χώρα εκτός του Ευρωπαϊκού Οικονομικού Χώρου (Ε.Ο.Χ.) ή σε διεθνή οργανισμό.

Η Διοργανώτρια  λαμβάνει  κατάλληλα οργανωτικά και τεχνικά μέτρα για την ασφάλεια των δεδομένων και την προστασία τους από τυχαία ή αθέμιτη καταστροφή, τυχαία απώλεια, αλλοίωση, απαγορευμένη διάδοση ή πρόσβαση και κάθε άλλη μορφή αθέμιτης επεξεργασίας. Περισσότερες πληροφορίες αναφορικά με το θέμα αυτό παρέχονται στο αντίστοιχο άρθρο Επεξεργασία ∆εδομένων Προσωπικού Χαρακτήρα αναφορικά με την Πολιτική Προστασία των Προσωπικών Δεδομένων στο www.fysikoaerioellados.gr/
Η Διοργανώτρια δεν φέρει  ευθύνη για τυχόν διαρροή των προσωπικών δεδομένων των συμμετεχόντων από την εκ μέρους τους κοινοποίηση τυχόν δεδομένων τους μέσα από σελίδες τρίτων ή άλλων ιστοσελίδων στο διαδίκτυο.
7.	ΔΩΡΟ – ΑΝΑΔΕΙΞΗ ΝΙΚΗΤΗ: 
Τα δώρα της Προωθητικής Ενέργειας είναι τα εξής:
Σετ καρέκλα με τιμονιέρα 	2

Play Station 5	2
Ακουστικά JBL Vibe Beam	2
Smartwatch	4

Οι νικητές θα αναδειχθούν κατόπιν κλήρωσης με την λήξη της Δ.Ε.Θ. εντός του διαστήματος 16 -20/09/2024. Η κλήρωση θα γίνει ανάμεσα στους 10 παίκτες που έχουν τους καλύτερους χρόνους ολοκλήρωσης του παιχνιδιού και θα βρίσκονται σε κατάταξη. Ο κάθε παίκτης έχει δικαίωμα απεριόριστων συμμετοχών στο παιχνίδι.  
Τα ονόματα των εκάστοτε δέκα πρώτων παικτών της Δράσης αναγράφονται στην κεντρική οθόνη του παιχνιδιού μετά την λήξη αυτού. Νικητές είναι οι παίκτες που το όνομά τους αναγράφεται πρώτο, δεύτερο, τρίτο, τέταρτο, πέμπτο, έκτο, έβδομο,όγδοο, ένατο και δέκατο στην οθόνη του παιχνιδιού στο περίπτερο της Διοργανώτριας, την Κυριακή 15 Σεπτεμβρίου 2024 στις 22:00, έχοντας κάνει τον καλύτερο χρόνο. Αν το όνομα κάποιου παίκτη συμπεριλαμβάνεται στην τελική κατάταξη περισσότερο από μία φορά, τότε στην διαδικασία της κλήρωσης έχει δικαίωμα σε 1 δώρο. Σε αυτή την περίπτωση το δώρο θα απονεμηθεί στον επόμενο κληρωθέντα παίκτη.
Τα Δώρα όσον αφορά στον αριθμό και το είδος τους είναι ήδη προκαθορισμένα και συγκεκριμένα και αποκλείεται ανταλλαγή ή εξαργύρωσή τους με χρήματα και δεν μεταβιβάζονται. 
Σε περίπτωση μη αποδοχής Δώρου/ μη καταλληλόλητας του Νικητή, το Δώρο του θα απονεμηθεί σε αναπληρωματικό νικητή.  
Ο ενδέκατος και δωδέκατος στην τελική κατάταξη της Δράσης θα είναι οι αναπληρωματικοί νικητές για τα Δώρα σε περίπτωση που δεν ανταποκριθεί κάποιος από τους νικητές κ.ο.κ. 
Οι νικητές της Δράσης θα ενημερωθούν τηλεφωνικά από την Διοργανώτρια κατά το χρονικό διάστημα από 23/09/2024 έως 27/09/2024.
Η κατακύρωση οποιουδήποτε Δώρου στον νικητή αυτού τελεί υπό την αναβλητική αίρεση της προσκόμισης και επίδειξης στη Διοργανώτρια, από τον εν λόγω νικητή των στοιχείων του, σε αντίθετη δε περίπτωση το σχετικό Δώρο τίθεται στην ευχέρεια του Διοργανωτή είτε για την ανάκληση ή αναδιανομή του δώρου. 
Η Διοργανώτρια δεν θα φέρει ευθύνη για άκαρπες προσπάθειες ειδοποίησης του νικητή που οφείλονται σε μη απόκριση, απουσία ή και λόγω μη προσέλευσης για παραλαβή του δώρου. 
Η παραλαβή του μπορεί να γίνει αφού απαραιτήτως επιδείξει ταυτότητα ή το διαβατήριο σε ισχύ για τις ανάγκες ταυτοπροσωπίας και υπογράψει προηγούμενα την φόρμα παραλαβής μεγάλων δώρων (Παράρτημα Α1).
Σε περίπτωση που ο νικητής δεν αποδεχθεί το Δώρο του, δεν τηρεί τις προϋποθέσεις που έχουν τεθεί ή για οποιονδήποτε λόγο δεν παραλάβει αυτό εντός της προθεσμίας που θα του γνωστοποιηθεί, χάνει το σχετικό δικαίωμά του. 
8.	ΔΙΑΔΙΚΑΣΙΑ ΠΑΡΑΛΑΒΗΣ ΤΩΝ ΔΩΡΩΝ: 
Το ΦΥΣΙΚΟ ΑΕΡΙΟ επικοινωνεί μόνο με τους νικητές των δώρων όπως αυτά αναφέρονται στη παράγραφο 7 του παρόντος για να τους ενημερώσει για την παραλαβή των δώρων τους.
Η παραλαβή τους μπορεί να γίνει αφού απαραιτήτως επιδείξουν ταυτότητα ή το διαβατήριο σε ισχύ για τις ανάγκες ταυτοπροσωπίας και υπογράψουν προηγούμενα την φόρμα παραλαβής δώρων (Παράρτημα Α1).
Το ΦΥΣΙΚΟ ΑΕΡΙΟ κατά την επικοινωνία με τους τυχερούς  μπορεί να ζητά οποιαδήποτε επιπλέον στοιχεία κρίνει απαραίτητα για την πιστοποίηση της ταυτότητας του  τυχερού – τελικού αποδέκτη του Δώρου.
Η ευθύνη του ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ περιορίζεται αποκλειστικά και μόνο στη διάθεση των προγραμματισμένων Δώρων.
Οι συμμετέχοντες παρέχουν τη συγκατάθεση τους και εξουσιοδότηση στην Διοργανώτρια για την προβολή του Προγράμματος ή/και των αποτελεσμάτων του μέσω ραδιοφώνου, τηλεόρασης, κινηματογράφου καθώς και μέσω του έντυπου και ηλεκτρονικού τύπου ή και του INTERNET (συμπεριλαμβανομένων των social media). Έτσι, η Διοργανώτρια επιφυλάσσεται να χρησιμοποιήσει και να δημοσιεύσει για διαφημιστικούς σκοπούς τον κατάλογο των τυχερών νικητών, φωτογραφίες, φιλμ και βίντεο και ενδεχομένως να χρησιμοποιήσει οποιοδήποτε ειδησεογραφικό στοιχείο σχετικό με το παρόν Πρόγραμμα για διαφημιστικούς σκοπούς, η δε συμμετοχή στο Πρόγραμμα παρέχει αυτομάτως και την προς τούτο συναίνεση και εκχώρηση των αναγκαίων πνευματικών δικαιωμάτων ατελώς,  χωρίς καμία περαιτέρω απαίτηση του Συμμετέχοντα ή αξίωση για καταβολή οποιασδήποτε αμοιβής ή αποζημιώσεως  για τους λόγους αυτούς.
Τα δώρα είναι προσωπικά, δεν μεταβιβάζονται και δεν ανταλλάσσονται με χρήματα ή με άλλα δώρα.
Οι συμμετέχοντες δεν έχουν ούτε αποκτούν κανένα απολύτως δικαίωμα πάνω στα σήματα, ονόματα, ενδείξεις, εμβλήματα και λοιπά διακριτικά της εταιρείας  ΦΥΣΙΚΟ ΑΕΡΙΟ και της Διαφημιστικής Εταιρείας.
Κάθε Συμμετέχων φέρει την αποκλειστική ευθύνη για την αλήθεια, την ακρίβεια, την πληρότητα και ορθότητα των υποβαλλόμενων στοιχείων. Ελλιπής ή ανακριβής δήλωση  των στοιχείων παρέχει δικαίωμα στις Διοργανώτριες καθ΄ολη την διάρκεια της Προωθητικής Ενέργειας, καθώς και μετά το τέλος της, να τον αποκλείσουν από τη διαδικασία απόδοσης του Δώρου, χωρίς καμία αξίωση ή απαίτηση εκ μέρους του.  
Η συμμετοχή στην Προωθητική Ενέργεια προϋποθέτει πρόσβαση των ενδιαφερομένων  με δικά τους τεχνικά μέσα. Η Διοργανώτρια Εταιρία και η Διαφημιστική δεν αναλαμβάνουν  καμία υποχρέωση αναφορικά με την εξασφάλιση, την υποβοήθηση της πρόσβασης στο διαδίκτυο ή την παροχή διευκολύνσεων σε κάθε ενδιαφερόμενο. 
9.	ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ – ΔΩΣΙΔΙΚΙΑ:
Οι παρόντες όροι διέπονται από το ελληνικό δίκαιο και οιαδήποτε τυχόν διαφορά σχετικά με τη Προωθητική Ενέργεια  θα επιλύεται από τα καθ' ύλη αρμόδια δικαστήρια των Αθηνών και η συμμετοχή στην Προωθητική Ενέργεια προϋποθέτει και συνεπάγεται ρητή και ανεπιφύλακτη αποδοχή του συνόλου των παρόντων Όρων.




ΠΑΡΑΡΤΗΜΑ Α1
ΦΟΡΜΑ ΠΑΡΑΛΑΒΗΣ ΔΩΡΩΝ
Κατόπιν νικητήριας συμμετοχής μου στην Προωθητική Ενέργεια «ENERGY CHAMPIONS – Ο αγώνας για μια πιο πράσινη Θεσσαλονίκη ξεκινάει τώρα!»  δηλώνω ότι αποδέχομαι το δώρο που περιγράφεται παρακάτω, σύμφωνα με τους όρους της Προωθητικής Ενέργειας, των οποίων έχω λάβει γνώση και τους οποίους αποδέχομαι ανεπιφύλαχτα στο σύνολό τους.
Με “X” είναι σημειωμένο στο κουτάκι το αντίστοιχο κερδισμένο δώρο.
Όνομα	 	Επώνυμο	 
Τηλέφωνο	 	Διεύθυνση	 
Πόλη	 	ΤΚ	 
Ημερομηνία Γέννησης	 	Αριθμός  Δελτίου Ταυτότητας	 
Ημερομηνία	 	Υπογραφή	 
Δώρο 	        

 
Δηλώνω υπευθύνως και ανεπιφύλακτα δια της παρούσης ότι μετά την παραλαβή του συμφωνηθέντος δώρου από την εταιρία δεν έχω ουδεμία άλλη απαίτηση έναντι αυτής.
Συναινώ στη χρήση σχετικού φωτογραφικού και οπτικοακουστικού υλικού για διαφημιστικούς σκοπούς.
Ναι              Όχι 

 
Ημερομηνία: ____ / ____/2024
 
 Υπογραφή
…………………………………………………..





ΕΝΗΜΕΡΩΣΗ ΣΥΜΜΕΤΕΧΟΝΤΩΝ
ΓΙΑ ΤΗΝ ΕΠΕΞΕΡΓΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ ΣΤΟ ΠΛΑΙΣΙΟ ΤΟΥ ΔΙΑΓΩΝΙΣΜΟΥ
1. Η προστασία των δεδομένων προσωπικού χαρακτήρα σας αποτελεί απόλυτη προτεραιότητα για την ΦΥΣΙΚΟ ΑΕΡΙΟ («Εμείς», «Εταιρεία», «Διοργανώτρια»). Για τον λόγο αυτόν διασφαλίζουμε με κατάλληλα μέτρα την προστασία των θεμελιωδών σας δικαιωμάτων και την απαρέγκλιτη τήρηση της ισχύουσας νομοθεσίας περί προστασίας των προσωπικών σας δεδομένων. 
2.  Στο πλαίσιο αυτό, η Εταιρεία μας, με την ιδιότητα του Υπευθύνου Επεξεργασίας, σε συμμόρφωση με τα άρθρα 13-14 του Γενικού Κανονισμού για την Προστασία Δεδομένων, σας παρέχει την παρούσα Ενημέρωση («Ενημέρωση») προς το σκοπό παροχής επαρκούς σας πληροφόρησης αναφορικά με το είδος και τον τρόπο επεξεργασίας των προσωπικών σας δεδομένων στο πλαίσιο των δραστηριοτήτων της.  Η Ενημέρωση ισχύει για εσάς εάν συμμετέχετε στην Προωθητική Ενέργεια με τίτλο «ENERGY CHAMPIONS – Ο αγώνας για μια πιο πράσινη Θεσσαλονίκη ξεκινάει τώρα!» (εφεξής «Διαγωνισμός» ή «Προωθητική Ενέργεια»). 
3.  Στο πλαίσιο της συμμετοχής σας στο Διαγωνισμό, θα συλλέξουμε απευθείας από εσάς και θα επεξεργαστούμε ορισμένα ή όλα τα παρακάτω προσωπικά σας δεδομένα:
•	Το ονοματεπώνυμο
•	Τον αριθμό τηλεφώνου σας
•	Τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας (email)
•	Την ταχυδρομική διεύθυνση σας.
4. Η ως άνω επεξεργασία προσωπικών δεδομένων είναι αναγκαία για τους ακόλουθους σκοπούς: (α) την διεξαγωγή του Διαγωνισμού και την εκτέλεση της σύμβασης στην οποία συμμετέχουν οι συμβαλλόμενοι συμμετέχοντες, (β) την κλήρωση των νικητών, (γ) την ταυτοποίηση και ανακοίνωση των νικητών, (δ) την αποστολή ή εξαργύρωση των δώρων, (ε) την προώθηση και την προβολή της Διοργανώτριας για εμπορικούς σκοπούς και (ζ) την ανταπόκριση της Διοργανώτριας στις υποχρεώσεις της, οι οποίες απορρέουν από τον Διαγωνισμό. 
5. Νομική βάση της επεξεργασίας είναι η συμβατική σχέση μας μαζί σας (άρθρο 6 § 1 β’ του ΓΚΠΔ) μέσω της αποδοχής των όρων του Διαγωνισμού και τη δήλωση συμμετοχής στην κλήρωση.
6. H χρήση και η επεξεργασία των προσωπικών σας δεδομένων εκτελείται από ειδικώς εξουσιοδοτημένο προσωπικό της Εταιρείας, υπό την τήρηση αυστηρών όρων εμπιστευτικότητας. 
7. Η Εταιρεία μας ενδέχεται να διαβιβάζει τα προσωπικά σας δεδομένα στους ακόλουθους εκτελούντες την επεξεργασία για λογαριασμό και κατ’ εντολή της: (α) σε εξουσιοδοτημένα μέλη της ομάδας μας (θυγατρικές, ελέγχουσα μητρική εταιρεία κ.λπ.), (β) σε παρόχους τεχνικής υποστήριξης και διαδικτυακής φιλοξενίας των υποδομών και συστημάτων πληροφορικής και επικοινωνιών αυτής, και (γ) στη συνεργαζόμενη με εμάς διαφημιστική εταιρεία με την επωνυμία MIND SETS I.K.E. Σε περίπτωση που υποχρεωθούμε από δικαστήριο ή άλλη διοικητική αρχή καθώς και σε κάθε άλλη περίπτωση που φέρουμε έννομη υποχρέωση προς τούτο, η Εταιρεία μας ενδέχεται να παραχωρήσει προσωπικά σας δεδομένα μέχρι της έκτασης που προβλέπεται από τον νόμο.
8. Διατηρούμε κατά κανόνα τα προσωπικά σας δεδομένα εντός του Ευρωπαϊκού Οικονομικού Χώρου. Ενδέχεται να διαβιβάζουμε προσωπικά σας δεδομένα σε τρίτες χώρες εκτός του Ευρωπαϊκού Οικονομικού Χώρου υπό την προϋπόθεση ή παρέχονται κατάλληλες εγγυήσεις για την προστασία των προσωπικών δεδομένων, όπως δεσμευτικοί εταιρικοί κανόνες ή πρότυπες συμβατικές ρήτρες της Ευρωπαϊκής Επιτροπής, τα οποία θέτουμε στη διάθεσή σας κατόπιν αιτήματός σας.
9. Τα δεδομένα που υπόκεινται σε επεξεργασία για τους σκοπούς συμμετοχής στο Διαγωνισμό, θα διατηρηθούν μέχρι και το πέρας του Διαγωνισμού και την ανάδειξη των νικητών αυτού και σε κάθε περίπτωση για χρονικό διάστημα έως και έξι (6) μηνών από την ολοκλήρωση του Διαγωνισμού. Τα δεδομένα μετά το πέρας των ανωτέρω θα καταστρέφονται ή/και θα διαγράφονται με ασφάλεια. Ενδέχεται να διατηρούμε προσωπικά δεδομένα και μετά την εκπλήρωση των σκοπών συλλογής και επεξεργασίας αυτών στις ακόλουθες περιοριστικά αναφερόμενες περιπτώσεις :
o	Εφόσον υφίσταται έννομη υποχρέωση μας από σχετική διάταξη νόμου.
o	Για τη χρήση ενώπιον φορολογικών και κοινωνικοασφαλιστικών αρχών, καθώς και κάθε άλλης ελεγκτικής αρχής στα πλαίσια της νόμιμης προθεσμίας παραγραφής.
o	Εφόσον απαιτείται για την καλή οργάνωση και λειτουργία μας υπό τον όρο ότι λαμβάνει χώρα ανωνυμοποίηση των δεδομένων.
o	Μέχρι την παραγραφή των σχετικών αξιώσεων για την προάσπιση των δικαιωμάτων και των έννομων συμφερόντων μας ενώπιον παντός αρμόδιου Δικαστηρίου και κάθε άλλης δημόσιας αρχής.
o	Εφόσον υφίσταται διοικητική ή δικαστική διαφορά, που σχετίζεται άμεσα ή έμμεσα με προσωπικά δεδομένα συμμετέχοντος, μέχρι την έκδοση αμετάκλητης δικαστικής απόφασης.
10. Έχετε τη δυνατότητα ανά πάσα στιγμή να ασκήσετε τα δικαιώματα της πρόσβασης, διόρθωσης, περιορισμού της επεξεργασίας, φορητότητας, εναντίωσης, αντίρρησης στην αυτοματοποιημένη λήψη αποφάσεων και προσφυγής στην αρμόδια Εποπτική Αρχή (Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα, Λ. Κηφισίας 1, Αθήνα, 115 23, https://www.dpa.gr/el/polites/katagelia_stin_arxi) με επικοινωνία μαζί μας στο ακόλουθο e-mail: contact-DPO@fysikoaerioellados.gr 


      </p>
        </div>    


    );

}

export default Terms;