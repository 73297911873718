import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import car1Image from '../../assets/images/car1.gif';
import car2Image from '../../assets/images/car2.gif';
import carBackground from '../../assets/backgrounds/carbackground.png'; // Εικόνα φόντου
import './Car.css';

const Car = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const [selectedCar, setSelectedCar] = useState(0);
  const history = useNavigate();

  const handleCarSelect = (carIndex) => {
    setSelectedCar(carIndex); // Μην προσθέτετε κάποιον αριθμό εδώ
  };

  const handleNext = async () => {
    if (selectedCar !== null) {
      try {
        // Ενημερώστε το αντίστοιχο έγγραφο στη συλλογή "data" με το πεδίο "car"
        await firestore.collection('data').doc(userId).update({
          car: selectedCar,
        });

        history(`/finalchoice?userId=${userId}`);
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }
  };


  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
    beforeChange: (current, next) => setSelectedCar(next), // Ενημέρωση της επιλογής κάθε φορά που αλλάζει το slide
  };

  return (
    <div className="car-container" style={{ backgroundImage: `url(${carBackground})` }}>
      <Slider {...settings} className="car-slider">
        <div className={`car-option ${selectedCar === 0 ? 'selected' : ''}`}>
          <img src={car1Image} alt="Car 1" />
        </div>
        <div className={`car-option ${selectedCar === 1 ? 'selected' : ''}`}>
          <img src={car2Image} alt="Car 2" />
        </div>
        {/* Προσθέστε εδώ τις υπόλοιπες επιλογές */}
      </Slider>
     <button className="next-button-car" onClick={handleNext}>
     Επόμενο
     </button>
    </div>
  )
};

export default Car;
