import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import CollectData from '../collectdata-page/CollectData';
import GenderPage from '../gender-page/GenderPage';
import Gender2 from '../gender2-page/Gender2';
import Nogame from '../nogame-page/Nogame';
import MaleAvatar from '../maleavatar-page/Maleavatar';
import FemaleAvatar from '../femaleavatar-page/Femaleavatar';
import Car from '../car-page/Car';
import Finalchoice from '../finalchoice-page/Finalchoice';
import Qr from '../qr-page/Qr';
import Terms from '../terms-page/Terms';
import './App.css';

function App() {
  const [formData, setFormData] = React.useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form data:', formData);
    // Κώδικας για αποθήκευση στο Firestore ή άλλη λογική αποθήκευσης δεδομένων
  };

  return (
    <Router>
      <div >
       
        <Routes>
          <Route path="/collectdata" element={<CollectData formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />} />
          <Route path="/gender-page" element={<GenderPage formData={formData} />} />
          <Route path="/gender2" element={<Gender2 />} />
          <Route path="/nogame" element={<Nogame />} />
          <Route path="/maleavatar" element={<MaleAvatar />} />
          <Route path="/femaleavatar" element={<FemaleAvatar />} />
          <Route path="/car" element={<Car />} />
          <Route path="/finalchoice" element={<Finalchoice />} />
          <Route path="/qr" element={<Qr />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/" element={<NavigationContainer />} />
        </Routes>
      </div>
    </Router>
  );
}

function NavigationContainer() {
  const location = useLocation();
  const showButton = !location.pathname.includes('/collectdata') && !location.pathname.includes('/gender-page');

  return (
    <div className="app-container">
        
      {/* Κωδικός που θα εμφανίζεται μόνο αν δεν είμαστε στις σελίδες CollectData και GenderPage */}
      {showButton && (
        <div id="next-button-container">
          <button className="button">
            <Link to="/collectdata">Επόμενο</Link>
          </button>
        </div>
      )}
      <Routes>
        <Route path="/collectdata" element={<CollectData />} />
        <Route path="/gender-page" element={<GenderPage />} />
      </Routes>
    </div>
  );
}

export default App;
