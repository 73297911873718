import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import qrBackground from '../../assets/backgrounds/qrbackground.png'; // Εικόνα φόντου
import './Qr.css';
import { firestore } from '../../services/firebase.service';

function Qr() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const history = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Ανάκτηση δεδομένων από τη βάση με βάση το userId
    fetchUserDataFromDatabase(userId)
      .then((fetchedUserData) => {
        setUserData(fetchedUserData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [userId]);

  const downloadQr = () => {
    const canvas = document.getElementById('qr-canvas');
    const qrImage = new Image();
    
    // Δημιουργία ενός καναβά με λευκό φόντο
    const canvasWithBorder = document.createElement('canvas');
    const contextWithBorder = canvasWithBorder.getContext('2d');
    canvasWithBorder.width = canvas.width + 150; // Αυξήστε το περίγραμμα κατά 10px από κάθε πλευρά
    canvasWithBorder.height = canvas.height + 150;
    
    // Ορίστε το φόντο σε λευκό χρώμα
    contextWithBorder.fillStyle = 'white';
    contextWithBorder.fillRect(0, 0, canvasWithBorder.width, canvasWithBorder.height);
    
    // Φτιάξτε μια νέα εικόνα με το QR Code που περιλαμβάνει το περίγραμμα
    qrImage.src = canvas.toDataURL('image/png');
    
    // Περιμένετε να φορτώσει η εικόνα του QR Code
    qrImage.onload = () => {
      // Αντιγραφή του QR Code με το περίγραμμα στον καναβά με λευκό φόντο
      contextWithBorder.drawImage(qrImage, 70, 70); // 10px offset από κάθε πλευρά
      
      // Δημιουργία του συνδέσμου για τη λήψη της εικόνας με λευκό φόντο
      const url = canvasWithBorder.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = 'qr_code_with_border.png'; // Όνομα αρχείου που θα ληφθεί
      a.click();
    };
  };
  
  
  
  

  return (
    <div className="qr-container" style={{ backgroundImage: `url(${qrBackground})` }}>
      {userData && (
        <div className="qr-image-container">
          <QRCode id="qr-canvas" value={JSON.stringify(userData)} size={200} />
        </div>
      )}
      <button className="download-button" onClick={downloadQr}>
        Download
      </button>
    </div>
  );
}

async function fetchUserDataFromDatabase(userId) {
  try {
    const docRef = firestore.collection('data').doc(userId);
    const docSnapshot = await docRef.get();

    if (!docSnapshot.exists) {
      throw new Error('User data not found');
    }

    const userData = docSnapshot.data();

    const selectedUserData = {
      name: userData.name,
      surname: userData.surname,
      userId: userData.userId,
      car: userData.car,
      avatar: userData.avatar,
    };

    return selectedUserData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
}

export default Qr;
