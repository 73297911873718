import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import Slider2 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import female1Image from '../../assets/images/female-avatar1.gif';
import female2Image from '../../assets/images/female-avatar2.gif';
import female3Image from '../../assets/images/female-avatar3.gif';
import femaleBackground from '../../assets/backgrounds/femalebackground.png'; // Εικόνα φόντου
import './Femaleavatar.css';

const Femaleavatar = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const history = useNavigate();

  const [selectedAvatar, setSelectedAvatar] = useState(3); // Αρχική επιλογή στο πρώτο GIF

  const handleNext = async () => {
    try {
     const selectedGif = [
       female1Image,
       female2Image,
        female3Image,
        // Προσθέστε εδώ τις υπόλοιπες εικόνες
      ][selectedAvatar - 3]; // Αντιστοιχία εικόνας με επιλογή

      await firestore.collection('data').doc(userId).update({
        avatar: selectedAvatar,
      });

      history(`/car?userId=${userId}`);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
    beforeChange: (current, next) => setSelectedAvatar(next + 3),// Ενημέρωση της επιλογής κάθε φορά που αλλάζει το slide
  };

  
  return (
    <div className="female-avatar-container" style={{ backgroundImage: `url(${femaleBackground})` }}>
      <Slider2 {...settings} className="avatar1-slider">
        <div className={`avatar1-option ${selectedAvatar === 3 ? 'selected' : ''}`}>
          <img src={female1Image} alt="Avatar 1" />
        </div>
        <div className={`avatar1-option ${selectedAvatar === 4 ? 'selected' : ''}`}>
          <img src={female2Image} alt="Avatar 2" />
        </div>
        <div className={`avatar1-option ${selectedAvatar === 5 ? 'selected' : ''}`}>
          <img src={female3Image} alt="Avatar 3" />
        </div>
        {/* Προσθέστε εδώ τις υπόλοιπες επιλογές */}
      </Slider2>
     

    <button className="next-button-female" onClick={handleNext}>
      Επόμενο
    </button>
    </div>
  );
};

export default Femaleavatar;
