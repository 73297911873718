import React, { useState } from 'react';
import { firestore } from '../../services/firebase.service';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './CollectData.css'; // Εισαγωγή του CSS αρχείου για τη σελίδα CollectData


function CollectData() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    over18: false,
    privacyPolicy: false,
    termsAndConditions: false,
  });

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
 
  const handleSubmit = () => {
    if (validateForm()) {
      const userRef = firestore.collection('data').doc();
      const userId = userRef.id;

      userRef
        .set({
          ...formData,
          userId: userId,
          time: new Date().toLocaleString(), 
        })
        .then(() => {
          console.log('Επιτυχής αποθήκευση στο Firestore');
          navigate(`/gender-page?userId=${userId}`);
        })
        .catch((error) => {
          console.error('Σφάλμα κατά την αποθήκευση στο Firestore:', error);
        });
    } else {
      console.log('Συμπληρώστε όλα τα υποχρεωτικά πεδία');
    }
  };

  const validateForm = () => {
    const { name, surname, phone, email, privacyPolicy, termsAndConditions } = formData;
    return name && surname && validatePhone(phone) && validateEmail(email) && (privacyPolicy && termsAndConditions);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="collect-data-container">
      <div className="input-container">
        
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Όνομα:"
        />
      </div>

      <div className="input1-container">
       
        <input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Επίθετο:"
        />
      </div>

      <div className="input2-container">
        
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Τηλέφωνο:"
        />
        {errors.phone && <span className="error">{errors.phone}</span>}
      </div>

      <div className="input3-container">
        
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email:"
        />
        {errors.email && <span className="error">{errors.email}</span>}
      </div>

      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            name="over18"
            checked={formData.over18}
            onChange={handleChange}
          />
          Δηλώνω υπεύθυνα ότι είμαι πάνω από 18 ετών
        </label>
      </div>

      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            name="privacyPolicy"
            checked={formData.privacyPolicy}
            onChange={handleChange}
          />
          Διάβασα και συμφωνώ με την {" "}
          <Link to="/terms" style={{ textDecoration: "underline", color: "inherit" }}>
      Πολιτική Απορρήτου
    </Link>
        </label>
      </div>

      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            name="termsAndConditions"
            checked={formData.termsAndConditions}
            onChange={handleChange}
          />
          Διάβασα και συμφωνώ με τους{" "}
          <Link to="/terms" style={{ textDecoration: "underline", color: "inherit" }}>
      Όρους & Προυποθέσεις
    </Link>
   
        </label>
      </div>
  

     

      <div className="button-container">
        <button className="next-buttonc" onClick={handleSubmit} disabled={!validateForm()}>
          Επόμενο
        </button>
      </div>
    </div>
  );
}

export default CollectData;
