import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Αντικαταστήστε τις τιμές με τις πληροφορίες από το δικό σας Firebase project
const firebaseConfig = {
  apiKey: 'AIzaSyCwjcJj3aEYTmXuFnPCYmX6Aq8piT6Z-cQ',
  authDomain: 'fadeth2023.firebaseapp.com',
  projectId: 'fadeth2023',
  storageBucket: 'fadeth2023.appspot.com',
  messagingSenderId: '1044272656297',
  appId: '1:1044272656297:web:df15707017e8f62c7b9eb8',
  measurementId: "G-WS5B45X5P8"
};

// Αρχικοποίηση του Firebase με τις ρυθμίσεις του project
firebase.initializeApp(firebaseConfig);

// Αναφορά στο Firestore
const firestore = firebase.firestore();

export { firebase, firestore };
