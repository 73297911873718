import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import finalBackground from '../../assets/backgrounds/finalbackground.png'; // Εικόνα φόντου
import './Finalchoice.css';

import choice0Image from '../../assets/images/final_choice_images/choice0.jpg';
import choice1Image from '../../assets/images/final_choice_images/choice1.jpg';
import choice2Image from '../../assets/images/final_choice_images/choice2.jpg';
import choice3Image from '../../assets/images/final_choice_images/choice3.jpg';
import choice4Image from '../../assets/images/final_choice_images/choice4.jpg';
import choice5Image from '../../assets/images/final_choice_images/choice5.jpg';
import choice6Image from '../../assets/images/final_choice_images/choice6.jpg';
import choice7Image from '../../assets/images/final_choice_images/choice7.jpg';
import choice8Image from '../../assets/images/final_choice_images/choice8.jpg';
import choice9Image from '../../assets/images/final_choice_images/choice9.jpg';
import choice10Image from '../../assets/images/final_choice_images/choice10.jpg';
import choice11Image from '../../assets/images/final_choice_images/choice11.jpg';
// Προσθέστε εδώ τις υπόλοιπες εικόνες choice2.png, choice3.png, κλπ.


const FinalChoice = () => {
  const location = useLocation();
  const history = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const [avatarChoice, setAvatarChoice] = useState(0);
  const [carChoice, setCarChoice] = useState(0);
  const [finalChoiceImage, setFinalChoiceImage] = useState('');

  useEffect(() => {
    // Fetch avatar and car choices from Firestore based on userId
    const userRef = firestore.collection('data').doc(userId);

    userRef.get().then((doc) => {
      if (doc.exists) {
        const userData = doc.data();
        setAvatarChoice(userData.avatar);
        setCarChoice(userData.car);

        // Calculate the index for the final choice image based on avatar and car choices
        const finalChoiceIndex = userData.avatar * 2 + userData.car ;


        // Set the path for the final choice image
        setFinalChoiceImage([
          choice0Image,
          choice1Image,
          choice2Image,
          choice3Image,
          choice4Image,
          choice5Image,
          choice6Image,
          choice7Image,
          choice8Image,
          choice9Image,
          choice10Image,
          choice11Image,
          // Προσθέστε εδώ τις υπόλοιπες εικόνες choice2Image, choice3Image, κλπ.
        ][finalChoiceIndex]);
      } else {
        console.log('No such document!');
      }
    });
  }, [userId]);

  const handleNext = async () => {
    try {
      // Update the Firestore document with the final choice
      const userRef = firestore.collection('data').doc(userId);
      await userRef.update({
        finalChoice: avatarChoice * 2 + carChoice,
      });

      // Redirect to the next page (e.g., Qr.js)
      history(`/qr?userId=${userId}`);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <div className="final-choice-container" style={{ backgroundImage: `url(${finalBackground})` }}>
      <img src={finalChoiceImage} alt="Final Choice" className="final-choice-image" />
      <button className="next2-button" onClick={handleNext}>
        Επόμενο
      </button>
    </div>
  );
};

export default FinalChoice;
